<template>
  <div>
    Message:
    <input
      v-model="message"
      type="text"
      class="form-control"
    >
    <button
      class="btn btn-primary"
      @click="sendMessage"
    >
      Send
    </button>
    <div>
      History:
      <pre>{{ history }}</pre>
    </div>
  </div>
</template>

<script>
import * as signalR from '@aspnet/signalr';

export default {
data () {
    return {
      coordinationHub: null,
      message: "",
      history: "",
    }
  },
  created () {
    try {
      let currentUser = this.$authenticator.getUser();
      let accessToken = currentUser.details.access_token;

      this.coordinationHub = new signalR.HubConnectionBuilder().withUrl("/api/coordinationHub", { accessTokenFactory: () => accessToken }).build();

      //this.coordinationHub.on("Connected", message => { /*handleConnected */ });
      //this.coordinationHub.on("Disconnected", message => { /*handleDisconnected */ });
      this.coordinationHub.on("ReceiveMessage", (user, message) => { this.history += user + ' ' + message + '\r\n' });
      this.coordinationHub.start().then(() => {
      //   this.coordinationHub.invoke("SendMessage", "NewUser", "Connected");
       }).catch(error => {
         // eslint-disable-next-line no-console
         console.error(error);
       });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  beforeDestroy () {
    this.coordinationHub.stop();
  },
  methods: {
    sendMessage () {
      this.coordinationHub.invoke("SendMessage", this.message);
    },
  }
}
</script>
